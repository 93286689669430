import React from "react"
import { SEO } from "../components/seo"

const NotFoundPage = () => (
  <div
    style={{
      margin: `0 auto`,
      maxWidth: 960,
      padding: `4rem 1.0875rem 1.45rem`,
    }}
  >
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist.</p>
  </div>
)

export default NotFoundPage
